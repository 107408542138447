define("fivejs-marine-sports/pods/loading/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5r0C98oK",
    "block": "[[[10,0],[14,1,\"preloader\"],[12],[13]],[],false,[]]",
    "moduleName": "fivejs-marine-sports/pods/loading/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});