define("fivejs-marine-sports/pods/components/single-page/our-services/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ehSqNZw0",
    "block": "[[[1,\"\\n\"],[10,\"section\"],[14,1,\"services\"],[14,0,\"services section-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[14,\"data-aos\",\"fade-up\"],[12],[1,\"\\n\\n    \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"Services\"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"Check our Services\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"row\"],[14,\"data-aos\",\"fade-up\"],[14,\"data-aos-delay\",\"200\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"col-md-6 mt-4 mt-md-0\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"icon-box\"],[12],[1,\"\\n            \"],[8,[39,2],[[16,0,[29,[[28,[37,3],[\"fa-icon\"],[[\"from\"],[\"fivejs-marine-sports/pods/components/single-page/our-services/styles\"]]]]]]],[[\"@icon\",\"@fixedWidth\",\"@size\"],[[30,2,[\"icon\"]],true,\"3x\"]],null],[1,\"\\n            \"],[10,\"h4\"],[12],[10,3],[14,6,\"#\"],[12],[1,[30,2,[\"title\"]]],[13],[13],[1,\"\\n            \"],[10,2],[12],[1,[30,2,[\"description\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@services\",\"service\"],false,[\"each\",\"-track-array\",\"fa-icon\",\"local-class\"]]",
    "moduleName": "fivejs-marine-sports/pods/components/single-page/our-services/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});