define("fivejs-marine-sports/pods/application/route", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends _route.default {
    model() {
      return {
        shop: {
          email: '5jsmarinesports@gmail.com',
          address: {
            barangay: 'Maribago',
            city: 'Lapu-Lapu City',
            country: 'Philippines',
            street: 'Bagumbayan 1',
            zipcode: 6015
          },
          contacts: ['+63 919 604-2399', '+63 927 562-2979'],
          name: '5JS Marine Sports'
        },
        services: [{
          description: 'Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.',
          icon: 'bus-alt',
          title: 'Transportation'
        }, {
          description: 'Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata.',
          icon: 'route',
          title: 'Tours'
        }, {
          description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
          icon: 'swimmer',
          title: 'Diving'
        }, {
          description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          icon: 'ship',
          title: 'Boat Services'
        }, {
          description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.',
          icon: 'store',
          title: 'Shops'
        }, {
          description: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi.',
          icon: 'umbrella-beach',
          title: 'Island Hopping'
        }]
      };
    }

  }

  _exports.default = ApplicationRoute;
});