define("fivejs-marine-sports/pods/components/single-page/hero/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lZWNnf7M",
    "block": "[[[10,\"section\"],[14,1,\"hero\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"hero-container\"],[14,\"data-aos\",\"fade-up\"],[14,\"data-aos-delay\",\"150\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Little. Different. Hopping.\"],[13],[1,\"\\n\\n    \"],[10,\"h2\"],[12],[1,\"Welcome To Don't Go To Them\"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"#about\"],[14,0,\"btn-get-started scrollto\"],[12],[1,\"Get Pumped\"],[13],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "fivejs-marine-sports/pods/components/single-page/hero/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});