define("fivejs-marine-sports/pods/components/single-page/footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "contact-info": "_contact-info_197e8o"
  };
  _exports.default = _default;
});