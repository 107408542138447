define("fivejs-marine-sports/pods/components/single-page/our-services/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "fa-icon": "_fa-icon_2clvs4"
  };
  _exports.default = _default;
});