define("fivejs-marine-sports/pods/index/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Feu2GZDh",
    "block": "[[[1,[28,[35,0],[\"Home\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@shop\",\"@services\"],[[30,1,[\"shop\"]],[30,1,[\"services\"]]]],null]],[\"@model\"],false,[\"page-title\",\"single-page\"]]",
    "moduleName": "fivejs-marine-sports/pods/index/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});